import * as React from "react";
import { graphql, PageProps } from "gatsby";

import BlogLandingTemplate from "@components/blogs/blogLandingTemplate";
import { BreadcrumbPageContext } from "@util/types";
import { Query } from "@graphql-types";

interface Props extends PageProps {
  pageContext: BreadcrumbPageContext;
  data: Query;
}

const BlogLanding = ({ pageContext, data }: Props) => {
  const { allSanityBlog, allSanityBlogCategory, allSanityBlogLandingPage } =
    data;
  const { hero, seo } = allSanityBlogLandingPage.nodes[0];

  return (
    <BlogLandingTemplate
      isAllBlogs
      pageContext={pageContext}
      allSanityBlog={allSanityBlog}
      allSanityBlogCategory={allSanityBlogCategory}
      hero={hero}
      seo={seo}
    />
  );
};

export default BlogLanding;

export const query = graphql`
  query BlogsQuery($iban: String) {
    allSanityBlog(
      sort: { fields: publishDate, order: DESC }
      filter: { regions: { elemMatch: { iban: { eq: $iban } } } }
    ) {
      nodes {
        ...blogPreview
      }
    }
    allSanityBlogCategory(
      filter: { main: { regions: { elemMatch: { iban: { eq: $iban } } } } }
    ) {
      nodes {
        _id
        order
        main {
          hero {
            ...sanityHero
            title
            heading
          }
          slug {
            current
          }
          title
        }
      }
    }
    allSanityBlogLandingPage(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        hero {
          ...sanityHero
        }
        additionalHeros {
          ...sanityHero
        }
      }
    }
  }
`;
